var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Список документов",
        visible: _vm.isDialogVisible,
        width: "30%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isDialogVisible = $event
        },
      },
    },
    [
      _c("el-date-picker", {
        staticClass: "width-100",
        attrs: {
          type: "daterange",
          "range-separator": "—",
          "start-placeholder": "От",
          "end-placeholder": "До",
          "value-format": "yyyy-MM-dd",
        },
        model: {
          value: _vm.dateRange,
          callback: function ($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange",
        },
      }),
      _c(
        "div",
        { staticClass: "dialog-btns" },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("Закрыть"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.dialogAction } },
            [_vm._v("Подтвердить")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }