










import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

@Component
export default class DateDialog extends Vue {
    @Prop({ type: Boolean, default: false })
    private isDialogVisible?: boolean;
    @Prop({ type: String, default: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")] })
    private dateRange?: string[];

    private visible: boolean = false;

    private dialogAction() {
        this.$emit("dialogAction", this.dateRange);
    }

    private closeDialog() {
        this.$emit("closeDialog");
    }

    @Watch("isDialogVisible")
    private onVisiblePropChange(val: boolean) {
        this.visible = val;
    }

    @Watch("visible")
    private onVisibleChange(val: boolean) {
        if (!val) this.closeDialog();
    }
}
